<mat-form-field
  id="search-field"
  class="smaller-shape smaller-font rounded secondary-background"
  appearance="outline"
  [ngClass]="{ 'w-100-percent': fullWidth }"
>
  <mat-label>Suche</mat-label>
  <input matInput euleAutofocus [(ngModel)]="search" (keyup)="typeSearch($event)" placeholder="Suche" #input data-cy="input-search-bar-searchTerm" />
  <mat-icon matPrefix>search</mat-icon>
</mat-form-field>
