import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EuleAutofocusDirective } from '../../directives/eule-autofocus.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'eule-search-bar',
  standalone: true,
  imports: [MatFormFieldModule, MatIconModule, MatInputModule, FormsModule, EuleAutofocusDirective, NgClass],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class SearchBarComponent {
  @Input() search: string = ''; // decorate the property with @Input()
  @Input() fullWidth: boolean = false;
  @Output() searchChange = new EventEmitter<string>();

  public typeSearch(event: Event) {
    this.search = (event.target as HTMLInputElement).value;
    this.searchChange.emit(this.search);
  }
}
