import { HighlightColors, MaterialThemeColors, TextColors } from '../app/core/services/theme.service';

export const defaultTheme: MaterialThemeColors = {
  primary: '#558b2f',
  accent: '#009688',
  warn: '#ff5722'
}

export const darkTheme: MaterialThemeColors = {
  primary: '#64dd17',
  accent: '#00bcd4',
  warn: '#ff5722'
}

export const defaultHighlights: HighlightColors = {
  primary: defaultTheme.primary,
  accent: defaultTheme.accent,
  secondary: '#7cb342',
  accent2: '#ff6e40',
  accent3: '#e91e63',
  accent4: '#0097a7',
  accent5: '#00838f',
  success: '#4caf50',
  info: '#ffc107',
  warn: '#ff5722',
  error: '#d50000',
  bronze: '#cd7f32',
  silver: '#C0C0C0',
  gold: '#ffd700',
  platinum: '#b0bec5',
  inactive: '#78909c',
  optional: '#82b1ff'
}

export const darkHighlights: HighlightColors = {...defaultHighlights,
  primary: darkTheme.primary,
  accent: darkTheme.accent,
}

export const defaultTextColors: TextColors = {
  primaryText: '#212629',
  secondaryText: '#6c757c',
  disabledText: '#a3abbd'
}

export const darkTextColors: TextColors = {
  primaryText: '#ced4dc',
  secondaryText: '#a3abbd',
  disabledText: '#8d97ac'
}
