import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  Query,
  query,
  QueryConstraint,
} from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { catchError, from, map, Observable, of, ReplaySubject, startWith, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { generateQueryConstraints } from '../../../util/firebase.helper';
import {
  CollectionQueryResponse,
  DocumentQueryResponse,
  OrderByCondition,
  QueryCondition,
} from '../../types/firebase-types';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public firebaseApp: FirebaseApp;
  public functions: Functions;
  public firestore: Firestore;

  constructor() {
    this.firebaseApp = this.initialize();
    //with no parameter, using default database
    this.firestore = getFirestore(this.firebaseApp);

    // FIXME: fix
    this.functions = getFunctions(this.firebaseApp, 'europe-west3');
  }

  public initialize() {
    return initializeApp(environment.firebaseConfig);
  }

  /**
   * Retrieves data from a document in Firestore.
   * @param {string} collectionPath - The path to the collection.
   * @param {string} id - The id of the document.
   * @param {boolean} skipIsFetching - if true startWith will not trigger emit.
   * @returns {Observable<DocumentQueryResponse>} An observable of the retrieved data.
   */
  public getDocumentData<T>(collectionPath: string, id: string, skipIsFetching?: boolean): Observable<DocumentQueryResponse<T>> {
    try {
      const docRef: DocumentReference<DocumentData, DocumentData> = doc(this.firestore, collectionPath, id);
      let documentQuery$: Observable<DocumentQueryResponse<T>> = from(getDoc(docRef)).pipe(
        map(snapshot => {
          if (!snapshot.exists()) throw new Error('Document does not exist!');
          return {
            data: {
              ...(snapshot.data() as T),
              id: snapshot.id,
              metadata: snapshot.metadata,
            },
            doc: snapshot,
            documentPath: docRef.path,
            isFetching: false,
          };
        }),
        catchError(error => {
          console.error(error);
          return of({ isFetching: false, hasError: true, error });
        }),
      );

      if (!skipIsFetching) {
        documentQuery$ = documentQuery$.pipe(startWith({ isFetching: true }));
      }

      return documentQuery$;
    } catch (error) {
      // Catch synchronous errors
      console.error(error);
      // Return an observable that emits an error state
      return of({ isFetching: false, hasError: true, error });
    }
  }

  /**
   * Retrieves data from a collection in Firestore.
   * @param {string} collectionPath - The path to the collection.
   * @param {QueryCondition[]} [queryConditions] - The query conditions.
   * @param {OrderByCondition[]} [orderByConditions] - The orderBy conditions.
   * @param {boolean} [skipIsFetching] - if true startWith will not trigger emit
   * @returns {Observable<CollectionQueryResponse>} An observable of the retrieved data.
   */
  public getCollectionData<T>(
    collectionPath: string,
    queryConditions?: QueryCondition[] | null,
    orderByConditions?: OrderByCondition[] | null,
    skipIsFetching?: boolean,
  ): Observable<CollectionQueryResponse<T>> {
    try {
      const collectionRef: CollectionReference<DocumentData, DocumentData> = collection(this.firestore, collectionPath);
      // Prepare query constraints from conditions
      const queryConstraints: QueryConstraint[] = generateQueryConstraints(queryConditions, orderByConditions);
      const q: Query<DocumentData> = query(collectionRef, ...queryConstraints);
      let collectionQuery$: Observable<CollectionQueryResponse<T>> = from(getDocs(q)).pipe(
        map(snapshot => {
          return {
            data: snapshot.docs.map(doc => ({
              ...(doc.data() as T),
              id: doc.id,
              metadata: doc.metadata,
              exists: doc.exists(),
            })),
            docs: snapshot.docs,
            collectionPath: collectionRef.path,
            isFetching: false,
          };
        }),
        catchError(error => {
          console.error(error);
          return of({ isFetching: false, hasError: true, error });
        }),
      );

      if (!skipIsFetching) {
        collectionQuery$ = collectionQuery$.pipe(startWith({ isFetching: true }));
      }
      return collectionQuery$;
    } catch (error) {
      // Catch synchronous errors
      console.error(error);
      // Returns an observable that matches the expected result
      return of({ isFetching: false, hasError: true, error });
    }
  }

  /**
   * Listens to changes in a Firestore collection and emits the updated data as an observable.
   *
   * This function sets up a Firestore query based on the provided conditions and listens for real-time updates.
   * It filters out local writes and only processes server-confirmed changes. If specific properties are tracked,
   * it only emits changes when those properties are updated.
   *
   * @template T - The type of the data being observed.
   * @param {string} collectionPath - The path to the Firestore collection.
   * @param {QueryCondition[] | null} [queryConditions] - Optional query conditions to filter the collection.
   * @param {OrderByCondition | null} [orderByCondition] - Optional order by condition to sort the collection.
   * @param {(keyof T)[]} [trackedProperties] - Optional list of properties to track for changes.
   * @param {ReplaySubject<boolean>} [stop$] - Optional observable to stop listening to changes.
   * @param {boolean} [excludeTrackedProperties] - Optional flag to exclude tracked properties from the emitted data (default: only emit on included props).
   * @returns {Observable<T[]>} An observable that emits the updated collection data.
   */
  public listenToCollectionChangesOnSnapshot<T>(collectionPath: string,
                                                queryConditions?: QueryCondition[] | null,
                                                orderByCondition?: OrderByCondition | null,
                                                trackedProperties?: (keyof T)[] | null,
                                                excludeTrackedProperties?: boolean,
                                                stop$?: ReplaySubject<boolean>): Observable<T[]> {
    const queryConstraints = generateQueryConstraints(queryConditions, orderByCondition
      ? [orderByCondition]
      : null);
    const collectionRef = collection(this.firestore, collectionPath);
    const q = query(collectionRef, ...queryConstraints);

    let observable$: Observable<T[]> = new Observable<T[]>(observer => {
      const unsubscribe = onSnapshot(
        q,
        snapshot => {
          if (snapshot.metadata.hasPendingWrites) {
            // Skip local writes, we only want server-confirmed changes
            return;
          }

          const colData = snapshot.docs.map(doc => {
            const data = doc.data();
            const prevData = doc.metadata.hasPendingWrites ? undefined : doc.data(); // Placeholder for potential previous data

            if (!trackedProperties?.length) return { ...data, id: doc.id } as T;

            const hasTrackedChanges = excludeTrackedProperties
              ? trackedProperties.every(prop => (data as T)[prop] === (prevData as T)?.[prop])
              : trackedProperties.some(prop => (data as T)[prop] !== (prevData as T)?.[prop]);

            if (hasTrackedChanges) {
              return {
                ...data,
                id: doc.id,
              } as T;
            }

            return null; // Skip untracked changes
          }).filter((docData): docData is T => docData !== null); // Filter out null values

          observer.next(colData);
        },
        error => observer.error(error.message),
      );

      // Return the unsubscribe function to clean up the listener
      return () => unsubscribe();
    });

    // Conditionally apply `takeUntil` if `stop$` is provided
    if (stop$) {
      observable$ = observable$.pipe(takeUntil(stop$));
    }

    return observable$;
  }

}
