import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { convertNumber } from './conversion-helper';

export const germanNumberExp: RegExp = new RegExp('^-?(\\d{1,3}(\\.\\d{3})*|\\d+)(,\\d+)?$');

export const oneToOneHundredExp: RegExp = new RegExp('^(100|[1-9][0-9]?)$');

export const germanNumberWithLeadingPlusAndHyphenInBetweenExp: RegExp = new RegExp('^[+]?(\\d+(,\\d*)?)(-([+]?(\\d+(,\\d*)?)))?$');

export function passwordMisMatchValidator(passwordToMatchControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    const passwordToMatch = control.parent?.get(passwordToMatchControlName);

    if (!password || !passwordToMatch?.value) {
      return null;
    }

    if (!passwordToMatch?.value) {
      return { mismatch: true };
    }

    return passwordToMatch.value !== password ? { mismatch: true } : null;
  };
}

export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined || typeof value === 'undefined';
}

export function maxFixedValueValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue: string | number = control.value;
    const valueToCheck: number | null = typeof controlValue === 'string' ? convertNumber(controlValue) : controlValue;

    if (valueToCheck !== null && valueToCheck > maxValue) {
      return { maxFixedValueExceeded: true };
    }

    return null;
  };
}

/**
 * Returns Error if control "adgnb" in parent form has negative value
 *
 * @export
 * @returns {ValidatorFn}
 */
export function adgnbNegativeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parentForm = control.parent;
    if (!parentForm) {
      return null;
    }

    const adgnbControl = parentForm.get('adgnb');

    if (adgnbControl && adgnbControl.value < 0) {
      return { adgnbNegative: true };
    }

    return null;
  };
}

export function lphDateNotBeforePreviousValidator(
  lphIndex: number,
  leistungsPhasenArray: FormArray<FormControl<number>>,
  handoverDateControl: FormControl
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    let valid: boolean = true;
    for (let i = 0; i < leistungsPhasenArray.controls.length; i++) {
      if (i === lphIndex) {
        continue;
      }
      const element = new Date(leistungsPhasenArray.at(i)?.value).valueOf();
      if (new Date(control.value).valueOf() < element) {
        valid = false;
      }
      if (handoverDateControl.value && new Date(control.value).valueOf() > new Date(handoverDateControl.value).valueOf()) {
        valid = false;
      }
    }

    return !valid ? { lphDatebefore: true } : null;
  };
}
export function lphDateNotBeforePreviousValidator2(lphIndex: number, leistungsPhasenArray: FormArray<FormControl<number>>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    let valid: boolean = true;
    for (let i = 0; i < lphIndex; i++) {
      const elementDate = new Date(leistungsPhasenArray.at(i)?.value).valueOf();
      if (new Date(control.value).valueOf() < elementDate) {
        valid = false;
      }
    }

    return !valid ? { lphDatebefore: true } : null;
  };
}

export function noMultipleSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';

    // Prüfen, ob der String zwei oder mehr aufeinanderfolgende Leerzeichen enthält
    const hasMultipleSpaces = /\s{2,}/.test(value);

    return hasMultipleSpaces ? { multipleSpaces: true } : null;
  };
}
