import { Injectable } from '@angular/core';
import { collection, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { Comment, TaskData } from '@eeule/eeule-shared/src/types';
import {
  DocumentData,
  DocumentReference,
  FirestoreError,
  QueryDocumentSnapshot,
  QuerySnapshot,
  deleteDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { BehaviorSubject, Observable, from, map, forkJoin } from 'rxjs';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public nextTaskNumber$: BehaviorSubject<number>;

  constructor(private _firebaseService: FirebaseService) {
    this.nextTaskNumber$ = new BehaviorSubject<number>(123);
  }

  public setTask(projectId: string, taskData: TaskData): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskData.id}`);
    return from(setDoc(docRef, { ...taskData, createTime: serverTimestamp(), updateTime: serverTimestamp() }));
  }

  public updateTask(projectId: string, taskData: TaskData): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskData.id}`);
    return from(updateDoc(docRef, { ...taskData, updateTime: serverTimestamp() }));
  }

  public getAllProjectTasks(projectId: string): Observable<TaskData[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/tasks`);
    return from(getDocs(colRef)).pipe(map(tasksSnaps => tasksSnaps.docs.map(tasksSnap => tasksSnap.data() as TaskData)));
  }

  public getLiveAllProjectTasks(projectId: string): Observable<TaskData[]> {
    const q = query(collection(this._firebaseService.firestore, `projects/${projectId}/tasks`));
    return new Observable(observer => {
      return onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(snapshot.docs.map((tasksSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => tasksSnap.data() as TaskData)),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public getProjectTask(projectId: string, taskId: string): Observable<TaskData> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}`);
    return from(getDoc(docRef)).pipe(map(taskSnap => ({ ...taskSnap.data(), id: taskSnap.id } as TaskData)));
  }

  /**
   * FIXME: Contains possible memory leak because of a open subscription. Needs to be checked.
   *
   * @param {string} projectId
   * @param {string} taskId
   * @returns {Observable<Comment[]>}
   *
   * @memberOf TaskService
   */
  public getLiveAllTaskComments(projectId: string, taskId: string): Observable<Comment[]> {
    const q = query(collection(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments`), orderBy('createdDate'));
    return new Observable(observer => {
      return onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) => observer.next(snapshot.docs.map(tasksSnap => tasksSnap.data() as Comment)),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public updateProjectTask(projectId: string, taskId: string, data: Partial<TaskData>) {
    const path: string = `projects/${projectId}/tasks`;
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, taskId);
    // Update the document with new data and timestamp
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      })
    );
  }

  public deleteProjectTaskComment(projectId: string, taskId: string, Commentd: string): Observable<void> {
    if (!projectId || !taskId || !Commentd) {
      throw new Error(`not all params set ${projectId}, ${taskId}, ${Commentd}}`);
    }
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments/${Commentd}`);
    return from(deleteDoc(docRef));
  }

  public setProjectTaskComment(projectId: string, taskId: string, comment: Comment): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments/${comment.id}`);
    return from(setDoc(docRef, comment));
  }

  public updateProjectTaskComment(projectId: string, taskId: string, comment: Partial<Comment>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments/${comment.id}`);
    return from(updateDoc(docRef, comment));
  }

  /**
   * Performs a bulk update of project tasks in Firestore.
   *
   * This method takes an array of task update objects, each containing an ID and partial data,
   * and updates each task in the specified project. The updates are performed in parallel using `forkJoin`.
   *
   * @param {string} projectId - The ID of the project.
   * @param {Array<{ id: string; data: Partial<TaskData> }>} documentsToUpdate - An array of objects containing task IDs and partial data to update.
   * @returns {Observable<void[]>} An Observable that completes when all update operations are done.
   */
  public performBulkUpdate(projectId: string, documentsToUpdate: Array<{ id: string; data: Partial<TaskData> }>): Observable<void[]> {
    const update$ = documentsToUpdate.map(o =>
      this.updateProjectTask(projectId, o.id, o.data)
    );
    return forkJoin(update$);
  }

  // /**
  //  * Attention: Adding a Document to a Task that not exists yet (adding document while on a New-task-interface that has not yet been saved), would cause an error.
  //  * In this case the task is created only with its ID and the (updated) array of documents. This is by current state necessary since in this workflow we also create
  //  * the codument in the storage and only reference it here. On the one hand this makes it smoother for the user since he does not have to save and reopen the task
  //  * before adding documents. On the other hand this could lead to tasks that stay incomplete (if the user starts task creating, adds doc and cancels the task creation).
  //  * Alternative would be to cancel and undo the whole workflow if the user cancels the new-task dialog. this would mean we need to clean up also the document from
  //  * all references and the storage.
  //  *
  //  * @param {string} projectId
  //  * @param {string} taskId
  //  * @param {string} documentId
  //  * @returns {Observable<Observable<void>>}
  //  *
  //  * @memberOf TaskService
  //  */
  // public addDocumentToTask(projectId: string, taskId: string, documentId: string): Observable<Observable<void>> {
  //   const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}`);
  //   return this.getProjectTask(projectId, taskId).pipe(
  //     map((task: TaskData) => {
  //       if (task) {
  //         const _array = task.attachmentsIds || [];
  //         return from(updateDoc(docRef, { attachmentsIds: [..._array, documentId] }));
  //       } else {
  //         return from(setDoc(docRef, { attachmentsIds: [documentId] }));
  //       }
  //     })
  //   );
  // }
}
