import { Injectable } from '@angular/core';
import { Bonus } from '@eeule/eeule-shared/src';
import { addDoc, collection, deleteDoc, doc, DocumentReference, serverTimestamp, updateDoc } from 'firebase/firestore';
import { from, Observable } from 'rxjs';
import { CollectionQueryResponse, DocumentQueryResponse, OrderByCondition, QueryCondition } from '../../../types/firebase-types';
import { FirebaseService } from '../firebase.service';

/**
 * @Author Christian Sanker
 * Service for managing bonuses in Firestore.
 */
@Injectable({
  providedIn: 'root',
})
export class BonusService {
  constructor(private _firebaseService: FirebaseService) {}

  /**
   * Adds a new bonus to the Firestore collection.
   * @param data Partial data of the bonus to be added.
   * @returns An observable emitting the document reference of the newly added bonus.
   */
  addBonus(data: Partial<Bonus>): Observable<DocumentReference> {
    const collectionRef = collection(this._firebaseService.firestore, `bonusses`);
    return from(
      addDoc(collectionRef, {
        ...data,
        createTime: serverTimestamp(),
        updateTime: serverTimestamp(),
      })
    );
  }

  /**
   * Retrieves a bonus from Firestore based on the provided ID.
   * @param id The ID of the bonus to retrieve.
   * @returns An observable emitting the document query response of the retrieved bonus.
   */
  getBonus(id: string): Observable<DocumentQueryResponse<Bonus>> {
    return this._firebaseService.getDocumentData<Bonus>(`bonusses`, id);
  }

  /**
   * Retrieves multiple bonuses from Firestore based on optional query conditions and order by conditions.
   * @param queryConditions Optional array of query conditions to filter the bonuses.
   * @param orderByCondition Optional order by condition to sort the bonuses.
   * @param skipIsFetching Optional flag to skip the isFetching state.
   * @returns An observable emitting the collection query response of the retrieved bonuses.
   */
  getBonuses(queryConditions?: QueryCondition[] | null, orderByCondition?: OrderByCondition | null, skipIsFetching?: boolean): Observable<CollectionQueryResponse<Bonus>> {
    const orderByConditions: OrderByCondition[] | null = orderByCondition ? [orderByCondition] : null;
    return this._firebaseService.getCollectionData<Bonus>(`bonusses`, queryConditions, orderByConditions, skipIsFetching);
  }

  /**
   * Updates an existing bonus in Firestore.
   * @param id The ID of the bonus to update.
   * @param data Partial data of the bonus to update.
   * @returns An observable emitting void upon successful update.
   */
  updateBonus(id: string, data: Partial<Bonus>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `bonusses`, id);
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      })
    );
  }

  /**
   * Deletes an existing bonus from Firestore.
   * @param id The ID of the bonus to delete.
   * @returns An observable emitting void upon successful deletion.
   */
  deleteBonus(id: string): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `bonusses`, id);
    return from(deleteDoc(docRef));
  }
}
