import { DgnbSubjectEnum } from '../app/enums/DgnbSubject.enum';

export function mapAndTranslateIndicatorSubjects(subject: DgnbSubjectEnum | string) {
  switch (subject) {
    case DgnbSubjectEnum.ENV: case 'ENV': case 'env': return 'Ökologische Qualität';
    case DgnbSubjectEnum.ECO: case 'ECO': case 'eco': return 'Ökonomische Qualität';
    case DgnbSubjectEnum.SOC: case 'SOC': case 'soc': return 'Soziokulturelle Qualität';
    case DgnbSubjectEnum.TEC: case 'TEC': case 'tec': return 'Technische Qualität';
    case DgnbSubjectEnum.PRO: case 'PRO': case 'pro': return 'Prozessqualität';
    case DgnbSubjectEnum.SITE: case 'SITE': case 'site': return 'Standortqualität';
    default: return '';
  }
}
