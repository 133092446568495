import { Injectable } from '@angular/core';
import { DocumentData as EuleDocumentData } from '@eeule/eeule-shared/src/types/index';
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreError,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { forkJoin, from, map, Observable, switchMap } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { ProjectService } from './project.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private _firebaseService: FirebaseService, private _projectService: ProjectService, private _storageService: StorageService) {}

  /**
   * FIXME: Contains possible memory leak because of a open subscription. Needs to be checked.
   *
   * @param {string} projectId
   * @returns {Observable<Comment[]>}
   *
   * @memberOf DocumentService
   */
  public getLiveAllProjectDocumentsFromFirestore(projectId: string): Observable<EuleDocumentData[]> {
    const q = query(collection(this._firebaseService.firestore, `projects/${projectId}/documents`));
    return new Observable(observer => {
      return onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(snapshot.docs.map(euleDocumentSnap => euleDocumentSnap.data() as EuleDocumentData)),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  public getAllProjectDocumentsFromFirestore(projectId: string): Observable<EuleDocumentData[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/documents`);
    return from(getDocs(colRef)).pipe(map(documentSnaps => (
      documentSnaps.docs.map(documentsSnap => documentsSnap.data() as EuleDocumentData))
    ));
  }

  public deleteDoc(documentId: string) {
    if (!this._projectService.project$.value) {
      throw Error('No Project set');
    }
    if (!documentId) {
      throw Error('No Document ID');
    }
    const path: string = `projects/${this._projectService.project$.value.id}/documents/${documentId}`;
    return from(deleteDoc(doc(this._firebaseService.firestore, path))).pipe(
      switchMap(() => {
        return this._storageService.deleteDocument(path);
      })
    );
  }

  /**
   * Updates a project document in Firestore with new data and a timestamp.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} documentId - The ID of the document to update.
   * @param {Partial<EuleDocumentData>} data - The partial data to update in the document.
   * @returns {Observable<void>} An Observable that completes when the update operation is done.
   */
  public updateProjectDocument(projectId: string, documentId: string, data: Partial<EuleDocumentData>): Observable<void> {
    // Construct the path to the documents collection within the specified project
    const path: string = `projects/${projectId}/documents`;

    // Create a reference to the document
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, documentId);

    // Update the document with new data and timestamp
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      })
    );
  }

  /**
   * Performs a bulk update of project documents in Firestore.
   *
   * This method takes an array of document update objects, each containing an ID and partial data,
   * and updates each document in the specified project. The updates are performed in parallel using `forkJoin`.
   *
   * @param {string} projectId - The ID of the project.
   * @param {Array<{ id: string; data: Partial<DocumentData> }>} documentsToUpdate - An array of objects containing document IDs and partial data to update.
   * @returns {Observable<void[]>} An Observable that completes when all update operations are done.
   */
  public performBulkUpdate(projectId: string, documentsToUpdate: Array<{ id: string; data: Partial<DocumentData> }>): Observable<void[]> {
    const update$ = documentsToUpdate.map(o =>
      this.updateProjectDocument(projectId, o.id, o.data)
    );
    return forkJoin(update$);
  }
}
