/**
 * Sorts array by key
 *
 * @param array
 * @param key
 * @param ascending
 * @returns
 */
export function sortByKey<T = unknown>(array: T[], key: string, ascending: boolean = true): T[] {
  const collator = new Intl.Collator(undefined, {
    numeric: array.length > 0 && typeof array[0][key as keyof T] === 'number',
    sensitivity: 'base',
  });

  return array.sort((b, a) => {
    return ascending
      ? collator.compare((<unknown>b[key as keyof T]) as string, (<unknown>a[key as keyof T]) as string)
      : collator.compare((<unknown>a[key as keyof T]) as string, (<unknown>b[key as keyof T]) as string);
  });
}

/**
 * Removes duplicates from an array of objects based on a specified key.
 * @template T - The type of the array elements.
 * @param {T[]} array - The input array.
 * @param {keyof T} key - The key based on which duplicates are identified.
 * @returns {T[]} An array containing only unique objects based on the specified key.
 */
export function removeDuplicates<T>(array: T[], key: keyof T): T[] {
  const uniqueSet = new Set(array.map(item => item[key]));
  return Array.from(uniqueSet).map((keyValue: unknown) => array.find(item => item[key] === keyValue) as T);
}

/**
 * Transforms an array of integers or numeric strings into a distinct and complete array.
 * This function removes any duplicates and fills in missing integers between the smallest
 * and largest integers in the original array. If any string cannot be parsed into an integer,
 * an error is thrown.
 *
 * @param {(number | string)[]} numbers - The array of integers or strings to process.
 * @returns {number[]} A new array that is sorted, contains no duplicates, and includes
 *                     every integer between the minimum and maximum.
 * @throws {Error} Throws an error if any string in the array cannot be parsed as an integer.
 */
export function makeArrayDistinctAndComplete(numbers: (number | string)[]): number[] {
  const parsedNumbers: number[] = numbers.map(item => {
    const parsed = parseInt(item.toString(), 10);
    if (isNaN(parsed)) {
      throw new Error(`Invalid input: "${item}" is not a parseable number.`);
    }
    return parsed;
  });

  // Remove duplicates by converting the array to a Set and then back to an array
  const uniqueNumbers = Array.from(new Set(parsedNumbers));

  // Sort the unique numbers to facilitate the filling process
  uniqueNumbers.sort((a, b) => a - b);

  // Determine the minimum and maximum values from the sorted array
  const min = uniqueNumbers[0];
  const max = uniqueNumbers[uniqueNumbers.length - 1];

  // Create a complete array that includes all integers from min to max
  const completeArray = [];
  for (let i = min; i <= max; i++) {
    completeArray.push(i);
  }

  return completeArray;
}

/**
 * Returns unique array
 * @param array
 * @private
 */
// export function uniqueArray<T = unknown>(array: T[]): T[] {
//   return array.filter((value: T, index: number, self: T[]) => {
//     return self.indexOf(value) === index;
//   });
// }
