/**
 * Converts a value to a boolean.
 * @param value - The value to convert
 */
export function getBoolean(value: unknown): boolean {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
    case 'ja':
      return true;
    default:
      return false;
  }
}

/**
 * Replaces German umlaut characters in a string with their respective two-letter representations.
 * @param {string} str - The input string possibly containing umlaut characters.
 * @param {string} replaceSpaceWithUnderScore
 * @returns {string} The modified string with umlaut characters replaced.
 */
export function replaceUmlauts(str: string, replaceSpaceWithUnderScore?: boolean): string {
  const umlautMap: Record<string, string> = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };

  const replaceUmlautsRes: string = str.replace(/[\u00dc\u00c4\u00d6\u00fc\u00e4\u00f6\u00df][a-zA-Z]?/g, (match: string) => {
    if (match.length === 2 && match[1].toLowerCase() === match[1]) {
      // Uppercase umlaut followed by lowercase letter
      return umlautMap[match[0]] + match[1];
    } else {
      // Umlaut character alone
      return umlautMap[match] || match;
    }
  });

  return replaceSpaceWithUnderScore ? replaceUmlautsRes.replace(/\s/g, '_') : replaceUmlautsRes;
}

/**
 * Replaces special characters in a given string with a specified replacement.
 * @param {string} str - The input string containing special characters.
 * @param {string} [replacer="-"] - The string used for replacement. Default is "".
 * @returns {string} The string with special characters replaced.
 */
export function replaceSpecialCharacters(str: string, replacer: string = ''): string {
  // eslint-disable-next-line
  return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, replacer);
}

/**
 * Converts a number or string from a specific locale format ('de' or 'en') to a JavaScript number.
 *
 * @param {number | string} n - The number or string to be converted.
 * @param {'de' | 'en'} [sourceFormat='de'] - The source format of the number. 'de' for German format (e.g., "1.234,56"), 'en' for English format (e.g., "1,234.56").
 * @returns {number | null} - The converted number. Returns `null` if the input cannot be converted.
 */
export function convertNumber(n: number | string, sourceFormat: 'de' | 'en' = 'de'): number | null {
  // If the input is already a number, return it as is.
  if (typeof n === 'number') {
    return n;
  }

  // Convert the input to a string for processing.
  let nAsString: string = n;

  // Handle different locale formats.
  switch (sourceFormat) {
    case 'de':
      // German format uses '.' for thousands separator and ',' for decimal separator.
      nAsString = nAsString
        .replace('.', '') // Remove thousands separator.
        .replace(',', '.'); // Replace decimal separator with '.'
      break;
    case 'en':
      // English format uses ',' for thousands separator.
      nAsString = nAsString.replace(',', ''); // Remove thousands separator.
  }

  // Parse the string to a floating-point number.
  const parsed: number = parseFloat(nAsString);

  // Check if the parsing was successful.
  if (!isNaN(parsed)) {
    return parsed;
  }

  // Return null if the input cannot be converted to a number.
  return null;
}

/**
 * Parses a number or string from a specific locale format ('de' or 'en') to a JavaScript number.
 * Throws an error if the input cannot be converted.
 *
 * @param {number | string} n - The number or string to be parsed.
 * @param {'de' | 'en'} [sourceFormat='de'] - The source format of the number. 'de' for German format, 'en' for English format.
 * @returns {number} - The parsed number.
 * @throws {Error} - Throws an error if the input cannot be converted to a number.
 */
export function parseNumber(n: number | string, sourceFormat: 'de' | 'en' = 'de'): number {
  // Convert the input using convertNumber function.
  const convertedNumber: number | null = convertNumber(n, sourceFormat);

  // If conversion fails, throw an error.
  if (!convertedNumber && convertedNumber !== 0) {
    throw new Error(`Error parsing string value of ${n} as float`);
  }

  // Return the successfully parsed number.
  return convertedNumber;
}

/**
 * Parses a number or string into a localized string representation.
 * @param n The number or string to parse.
 * @param sourceFormat The format of the number if it's provided as a string. Defaults to 'de'.
 * @param maxDigits the maximal number of fraction digits.
 * @returns The localized string representation of the number.
 */
export function parseNumberAsLocaleString(n: number | string, sourceFormat: 'de' | 'en' = 'de', maxDigits: number = 2): string {
  // If the input is already a string, return it as is
  if (typeof n === 'string') {
    return n;
  }
  return getFormattedNumber(n, sourceFormat, 0, maxDigits);
}

/**
 * Formats a number according to the specified locale and digit constraints.
 *
 * @param {number} n - The number to format.
 * @param {string} [locale='de-DE'] - The locale string to use for formatting. Default is 'de-DE'.
 * @param {number} [minDigits=0] - The minimum number of fraction digits. Default is 0.
 * @param {number} [maxDigits=2] - The maximum number of fraction digits. Default is 2.
 * @returns {string} The formatted number as a string.
 */
export function getFormattedNumber(n: number, locale: string = 'de-DE', minDigits: number = 0, maxDigits: number = 2): string {
  return n.toLocaleString(locale, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
}

/**
 * Removes thousands separators from a string.
 *
 * @param {string} n - The input string containing thousands separators.
 * @returns {string} The string with thousands separators removed.
 */
export function removeThousandsSeparator(n: string): string {
  return n.replace(/\./g, '');
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - The input string.
 * @returns {string} The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(str: string): string {
  if (!str) return str;  // Return the original string if it's empty or undefined
  return str.charAt(0).toUpperCase() + str.slice(1);
}
